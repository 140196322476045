<template>
  <div>
    <b-card-title title-tag="h2" class="mb-4">
      {{ $t("pages.checkEmail.title") }} 📨
    </b-card-title>

    <b-card-text class="mb-4">
      {{ $t("pages.checkEmail.subtitle") }}
    </b-card-text>

    <b-card-text>
      {{ $t("pages.checkEmail.spam1") }}
      <b-link :to="{ name: 'foravila-forgot-password' }">
        {{ $t("pages.checkEmail.spam2") }}
      </b-link>
    </b-card-text>

    <p class="text-center mt-5">
      <b-link :to="{ name: 'foravila-login' }">
        <feather-icon icon="ChevronLeftIcon" class="mr-1" />
        {{ $t("pages.checkEmail.goToLogin") }}
      </b-link>
    </p>
  </div>
</template>

<script>
import { BLink, BCardTitle, BCardText } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BCardTitle,
    BCardText,
  },
};
</script>
